<template>
  <div class="container" v-if="!!user">
    <div class="row">
      <div class="col-sm-8 pr-1">
        <base-card header="bg-soft-danger">
          <template #header>بياناتي</template>
          <template #body>
            <form @submit.prevent="update" class="container">
              <div class="row">
                <div class="col-sm-3"><label>الإسم</label></div>
                <div class="col-sm-9">
                  <form-input class="bg-transparent" id="name" :value="user.name"></form-input>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3"><label>إسم المستخدم</label></div>
                <div class="col-sm-9">
                  <form-input class="bg-transparent" id="username" :value="user.username"></form-input>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3"><label>البريد الإلكتروني</label></div>
                <div class="col-sm-9">
                  <form-input class="bg-transparent" id="email" :value="user.email"></form-input>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button class="float-left btn btn-primary rounded-0">حفظ</button>
                </div>
              </div>
            </form>
          </template>
        </base-card>
      </div>
      <div class="col-sm-4 pl-1">
        <base-card header="bg-soft-danger" body="pt-0">
          <template #header>أرقام الهواتف</template>
          <template #body>
            <mobiles v-if="showMobiles" :user-id="authId"></mobiles>
            <p v-else class="link" @click="setShowMobiles">عرض تفاصيل أرقام الهاتف</p>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import Mobiles from "@/components/Users/Mobile/Mobiles";
import FormInput from "@/components/Forms/FormInput";
import BaseCard from "@/components/UI/BaseCard";
import profiles from "@/mixins/profiles";

export default {
  components: {BaseCard, FormInput, Mobiles},
  mixins: [profiles],
  data() {
    return {
      showMobiles: false,
    };
  },
  methods: {
    setShowMobiles() {
      this.showMobiles = !this.showMobiles;
    }
  }
}
</script>